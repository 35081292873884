// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collection-item-tsx": () => import("./../src/pages/collection-item.tsx" /* webpackChunkName: "component---src-pages-collection-item-tsx" */),
  "component---src-pages-collection-tsx": () => import("./../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-instruction-tsx": () => import("./../src/pages/instruction.tsx" /* webpackChunkName: "component---src-pages-instruction-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-location-page-tsx": () => import("./../src/pages/location_page.tsx" /* webpackChunkName: "component---src-pages-location-page-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-product-tsx": () => import("./../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */)
}

